import {Button, Checkbox, Col, Form, Input, Modal, Row} from 'antd';
import React, {useEffect} from 'react';
import {atom, useAtom} from "jotai";

import {usePlotMolecule} from "../../../Library/PlotMolecule";
import {atomUserInfo, atomUserInfoTrigger,} from "../../../Data/Atoms";
import UserAnnotationSubmitModalData, {atomShowModal, atomSubmissionTrigger} from "./UserAnnotationSubmitModalData";

import { createModuleLogger } from '../../../Library/logger';

const logger = createModuleLogger('UserAnnotationSubmitModal');

export const defaultAnnotationData = {
    type: "",
    annotation_user_name: "",
    mol_name: "",
    mol_adduct: "",
    mol_smiles: "",
    db: "",
    db_id: "",
    score: 0,
    delta_mz: 0,
}
export const atomAnnotationModalData = atom(defaultAnnotationData);

const UserAnnotationSubmitModal = () => {
    const [stateAtomShowModal, setAtomShowModal] = useAtom(atomShowModal);
    ////////////////////////////////////////////////////////////////////////////////
    // Only the user can submit the annotation
    const [stateUserInfo,] = useAtom(atomUserInfo);
    const [, setUserInfoTrigger] = useAtom(atomUserInfoTrigger);

    // Show modal when the user is not logged in
    useEffect(() => {
        if (stateAtomShowModal && stateUserInfo.name === "") {
            setUserInfoTrigger(true)
        }
    }, [stateAtomShowModal])

    ////////////////////////////////////////////////////////////////////////////////
    const [stateAnnotationModalData,] = useAtom(atomAnnotationModalData);
    const [contextPlotMolecule, setPlotMoleculeSmiles] = usePlotMolecule({width: 450, height: 200});
    useEffect(() => {
        logger.debug("stateAnnotationModalData", stateAnnotationModalData)
    }, [stateAnnotationModalData]);
    const [form] = Form.useForm();

    ////////////////////////////////////////////////////////////////////////////////
    // Set the form values when the submission data is loaded
    useEffect(() => {
        if (stateAnnotationModalData) {
            const molSmiles = stateAnnotationModalData.mol_smiles
            let comments = ""
            if (stateAnnotationModalData.type === "library_search") {
                comments = `When searching the ${stateAnnotationModalData.db} library, this spectrum showed an entropy similarity of ${(stateAnnotationModalData.score || 0).toFixed(3)} with the library spectrum ID: ${stateAnnotationModalData.db_id}. The mass error for this match was: ${(stateAnnotationModalData.delta_mz || 0).toFixed(3)} Da.`
            } else if (stateAnnotationModalData.type === "annotation_search") {
                comments = `When searching the annotation library, this spectrum showed an entropy similarity of ${(stateAnnotationModalData.score || 0).toFixed(3)} with spectrum annotated by ${stateAnnotationModalData.annotation_user_name}. The mass error for this match was: ${(stateAnnotationModalData.delta_mz || 0).toFixed(3)} Da.`
            }
            form.setFieldsValue(
                {
                    is_hidden: false,
                    type: stateAnnotationModalData.type,
                    mol_name: stateAnnotationModalData.mol_name,
                    mol_adduct: stateAnnotationModalData.mol_adduct,
                    mol_smiles: molSmiles,
                    comments: comments,
                }
            )
            setPlotMoleculeSmiles(molSmiles)
        }
    }, [stateAnnotationModalData])

    ////////////////////////////////////////////////////////////////////////////////
    const [, setSubmissionTrigger] = useAtom(atomSubmissionTrigger);
    const submitUserAnnotation = () => {
        if (stateUserInfo.name === "") {
            setUserInfoTrigger(true)
        } else {
            setSubmissionTrigger({...form.getFieldsValue(), shouldSubmit: true})
        }
    };

    return <>
        <UserAnnotationSubmitModalData/>
        <Modal title="Submit annotation" open={stateAtomShowModal} footer={null} width={650}
               destroyOnClose={true}
               onCancel={() => setAtomShowModal(false)}>
            <br/>
            <Form name="basic" labelCol={{span: 6}} wrapperCol={{span: 16}}
                  initialValues={stateAnnotationModalData}
                  form={form} onFinish={submitUserAnnotation}>

                <Form.Item label="Molecular name" name="mol_name" style={{marginBottom: "1em"}}>
                    <Input/>
                </Form.Item>

                <Form.Item label="SMILES" name="mol_smiles" style={{marginBottom: "1em"}}
                           onChange={(e) => {
                               setPlotMoleculeSmiles(e.target.value)
                           }}
                           rules={[{required: false, message: 'SMILES is required!'}]}>
                    <Input/>
                </Form.Item>

                <Row justify={"center"}>
                    <Col>
                        <div>{contextPlotMolecule}</div>
                    </Col>
                </Row>

                <Form.Item label="Adduct" name="mol_adduct" style={{marginBottom: "1em"}}
                           rules={[{required: false, message: 'Adduct is required!'}]}>
                    <Input/>
                </Form.Item>

                <Form.Item label="Comments" name="comments" style={{marginBottom: "0em"}}>
                    <Input.TextArea rows={6}/>
                </Form.Item>

                <Form.Item label="" name="is_hidden" style={{marginBottom: "0em"}}
                           valuePropName="checked" wrapperCol={{offset: 6, span: 16}}>
                    <Checkbox>Hide this spectrum from output</Checkbox>
                </Form.Item>

                {/*<Form.Item label="Labels" name="tags" style={{marginBottom: "0em"}}*/}
                {/*           wrapperCol={{offset: 6, span: 16}}>*/}
                {/*    <Tag closeIcon>Tag 1</Tag>*/}
                {/*</Form.Item>*/}

                <Form.Item wrapperCol={{offset: 10, span: 14}} style={{marginTop: "1em", marginBottom: "0em"}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>

            </Form>
        </Modal>
    </>
}

export default UserAnnotationSubmitModal
